import "./sources.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren<any> {
  urls: string[];
}

const PostSources: React.FC<Props> = (props: Props) => {
  if (props.urls.length < 1) {
    return;
  }
  const urls = [];
  props.urls.forEach((url, index) => {
    urls.push(
      <li key={index}>
        <a href={url} target={"_blank"} rel="noreferrer">
          {url}
        </a>
      </li>
    );
  });
  return (
    <div className={"m-sources"}>
      <p className={"h4"}>Fuentes</p>
      <ul>{urls}</ul>
    </div>
  );
};

export default PostSources;
