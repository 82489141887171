import * as React from "react";
import { PropsWithChildren } from "react";
import Helmet from "react-helmet";

import { Post } from "../../contexts/blog/domain/post";
import { logo } from "../../data/config";

interface PropsPost extends PropsWithChildren<any> {
  post: Post;
}

const PostMarkupSchema: React.FC<PropsPost> = (props: PropsPost) => {
  const { post } = props;
  const markup = `
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://obelionfisioterapia.com/${post.slug}/"
  },
  "headline": "${post.title}",
  "description": "${post.seo.description}",
  "image": "${post.imagen.asset.gatsbyImageData.images.fallback.src}",
  "author": {
    "@type": "Person",
    "name": "${post.authors[0].name}",
    "image": "${
      post.authors[0].photo.asset.gatsbyImageData.images.fallback.src
    }",
    "sameAs": [
      "${post.authors[0].socials?.instagram}",
      ${
        post.authors[0].socials?.facebook
          ? `"${post.authors[0].socials?.facebook}",`
          : ""
      }
      ${
        post.authors[0].socials?.twitter
          ? `"${post.authors[0].socials?.twitter}"`
          : ""
      }
    ],
    "jobTitle": "${post.authors[0].roles.map((role) => `${role.name}`)}",
    "worksFor": {
      "@type": "Organization",
      "name": "Obelion Fisioterapia Zaragoza"
    }
  },
    "publisher": {
    "@type": "Organization",
    "name": "Obelion",
    "logo": {
      "@type": "ImageObject",
      "url": "${logo}"
    }
  },
  "datePublished": "${post.publishedAt}",
  "dateModified": "${post.updatedAt}"
}
`;
  return (
    <Helmet>
      <script type="application/ld+json">{markup}</script>
    </Helmet>
  );
};

export default PostMarkupSchema;
