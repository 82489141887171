import "./post.scss";

import BlockContent from "@sanity/block-content-to-react";
import { graphql, useStaticQuery } from "gatsby";
import { PageContext } from "gatsby/internal";
import * as React from "react";

import PostMarkupSchema from "../../components/SEO/PostMarkupSchema";
import { SEO } from "../../components/SEO/SEO";
import { Post } from "../../contexts/blog/domain/post";
import PostSources from "../../views/blog/post/PostSources/PostSources";
import Hero from "../../views/shared/hero/Hero";
import Layout from "../../views/shared/layout/layout";

interface PostPageContext extends PageContext {
  post: Post;
}

const PagePost: React.FC<PostPageContext> = (props: PostPageContext) => {
  const { post } = props.pageContext;
  const data = useStaticQuery(graphql`
    query sanitySettingQuery {
      site {
        siteMetadata {
          projectId
          dataset
        }
      }
    }
  `);
  const { projectId, dataset } = data.site.siteMetadata;

  let title_seo = "test";
  if (post.seo.title !== "") {
    title_seo = post.seo.title;
  }

  const categories_render = [];
  post.categories.forEach((category) => {
    categories_render.push(
      <p className={"a-category-link a-category-link--post"}>
        {category.title}
      </p>
    );
  });
  return (
    <Layout>
      <SEO
        title={`${title_seo}`}
        description={post.seo.description}
        canonical={post.slug}
        image={post.imagen.asset.gatsbyImageData.images.fallback.src}
      />
      <PostMarkupSchema post={post} />
      <Hero
        title={post.title}
        photo={post.imagen.asset.gatsbyImageData.images.fallback.src}
        photo_alt={post.background_alt}
        color={"#A3BCC4"}
      >
        {categories_render}
      </Hero>
      <main className="docs m-post-content">
        <BlockContent
          blocks={post.content}
          projectId={projectId}
          dataset={dataset}
        />
        {post.sources.length > 0 && <PostSources urls={post.sources} />}
      </main>
    </Layout>
  );
};
export default PagePost;
